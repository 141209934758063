import React from 'react';
import './cssgs';
import Navbar from './NavbarSub';
import Header from './HeaderSub';
import Footer from '../layout/Footer';
import Banner from '../content/AboutUs/Banner';
import Exp from '../content/AboutUs/Experience';
import Accreditation from '../content/AboutUs/Accreditation';
import OurTeam from '../content/AboutUs/OurTeam';
import ContactBus from '../content/ContactBus';
import Story from '../content/AboutUs/Story';
import BrandValue from '../content/AboutUs/BrandValue';
import Partner from '../content/AboutUs/Partner';
import Mobile from '../content/Mobile';
import SEO from '../SEO';
// import ScriptTag from 'react-script-tag';

import SearchBar from '../content/Searchbar';


import Helmet from 'react-helmet';
import JSberhasil from './jsberhasil';

const LayoutSearchBar = () => {
	return (
		<React.Fragment>
			<Helmet
				bodyAttributes={{
					class:
						'home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074'
				}}
			/>
			<SEO title="SEARCH BAR" description="PML SEARCH BAR" />
			{/* <div id="loftloader-wrapper" className="pl-imgloading" data-show-close-time="15000">
                <div className="loader-inner">
                    <div id="loader">
                        <div className="imgloading-container">
                            <span style= {{backgroundImage: `url(${LoadingLogo})`}} ></span>
                        </div>
                        <img alt="loader image" src={LoadingLogo} />
                    </div>
                </div>
                <div className="loader-section section-fade">
                </div>
                <div className="loader-close-button">
                    <span className="screen-reader-text">Close</span>
                </div>
            </div> */}

			<div id="perspective">
				<Mobile />
				<div id="wrapper" class="hasbg transparent">
					<div id="elementor-header" class="main-menu-wrapper">
						<div
							data-elementor-type="wp-post"
							data-elementor-id="4287"
							class="elementor elementor-4287"
							data-elementor-settings="[]"
						>
							<div class="elementor-inner">
								<div class="elementor-section-wrap">
								
									<Header />
									
									<Navbar />
									
								</div>
							</div>
						</div>
					</div>
					<div id="elementor-sticky-header" className="main-menu-wrapper">
						<div
							data-elementor-type="wp-post"
							data-elementor-id="4287"
							className="elementor elementor-4287"
							data-elementor-settings="[]"
						>
							<div className="elementor-inner">
								<div className="elementor-section-wrap">
									<Navbar />
								</div>
							</div>
						</div>
					</div>

					<div id="page-content-wrapper" class="">
						<div class="inner">
							<div class="inner-wrapper">
								<div class="sidebar-content fullwidth">
									<div
										data-elementor-type="wp-page"
										data-elementor-id="5637"
										class="elementor elementor-5637"
										data-elementor-settings="[]"
									>
										<div class="elementor-inner">
											<div class="elementor-section-wrap">   
												<SearchBar />
												{/* <Searchresult/> */}
												{/* <ContactBus /> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<JSberhasil />
		</React.Fragment>
	);
};

export default LayoutSearchBar;
